import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setOptions, setDisabledByValue } from './selectOption';
import { searchText } from '../../actions/advanceSearch/advanceSearchAction';
import { userManagementData } from '../../actions/userManagement/userManagementAction';
import { FormattedMessage, injectIntl } from 'react-intl';
import api from '../../service/api';
import http from '../../service/httpService';
import { getOrganizationCategoryDetailsNew } from '../../actions/navigation/organizationmanagement/organizationCategoryDetailsActions';

class GlobalSearchBar extends Component {
  constructor() {
    super();
    this.state = {
      isActive: false,
      isSearch: false,
      searchtext: null,
      searchList: [],
      categoryList: [],
      searchKeys: [],
      childLevel: '',
      parentLevel: ''
    }
  }

  async componentDidMount() {
    await this.initialSetup();
  }

  handleActive = (isActiveValue) => {
    this.setState({
      isActive: isActiveValue
    })
  }

  handleSearch = async () => {
    let { searchList, searchtext } = this.state;
    if(!searchList || !searchList.length){
      await this.initialSetup();
    }

    var req = { searchtext };
    for (let i = 0; i < searchList.length; i++) {
      const ele = searchList[i];
      if (ele && ele.value !== "" && ele.name !== "Category") {
        let obj = { [ele.value]: ele.filterValue }
        req = { ...req, ...obj }
      }
    }

    await this.props.searchText(req);

    this.setState({
      isActive: false,
    })
  }

  getSearchKeys = (searchList) => {
    const keys = [];
    for (let index = 0; index < searchList.length; index++) {
      const element = searchList[index];
      if (element.value !== "" && element.filterValue !== "") {
        keys.push({
          name: element.name,
          nameStringID: element.nameStringID
        });
      }
    }
    return keys;
  }

  onSelectCategory = async (category, index, isNewRow) => {
    let { searchList } = this.state;
    if(searchList.length === 6 && this.props.path === '/eventLibrary'){
      alert(this.props.intl.formatMessage({ id: 'KC0880' }))
      return;
    }
    let searchVal = { ...category, filterValue: '', disabled: true };
    const categoryKey = searchList.findIndex((e) => e.name === "Category");
    searchList.splice(categoryKey, 1);

    if (isNewRow) {
      await searchList.push(searchVal);
      await searchList.push({
        "disabled": false,
        "name": "Category",
        "nameStringID": "KC0048",
        "placeholder": "KC0037",
        "selectedKey": "",
        "value": "",
        "childValues": []
      });
      this.setState({ searchList, childLevel: '', parentLevel: '', searchtext:'' })
    } else {
      searchList[index] = searchVal;
      await searchList.push({
        "disabled": false,
        "name": "Category",
        "nameStringID": "KC0048",
        "placeholder": "KC0037",
        "selectedKey": "",
        "value": "",
        "childValues": []
      });
      this.setState({ searchList, childLevel: '', parentLevel: '', searchtext:'' })
    }
  }

  onEnterValue = (index, value) => {
    let { searchList } = this.state;

    searchList[index]['filterValue'] = value;
    this.setState({ searchList });
  }

  removeItem = async (index) => {
    let { searchList, categoryList } = this.state;

    categoryList = await setDisabledByValue(categoryList, searchList[index].value, false);
    searchList.splice(index, 1);

    if (searchList.length === 1) {
      await this.handleSearch();
    }

    this.setState({ searchList, categoryList });
  }

  removeFromToast = async (keyName) => {
    let { searchList } = this.state;
 
    let index = await searchList.findIndex((e) => e.name === keyName);

    await this.removeItem(index);
    await this.handleSearch();
  }

  getCategoryValueIsDisabled = (value) => {
    let { searchList } = this.state;
    let disabledValue = false;
    for (let index = 0; index < searchList.length; index++) {
      const searchListValue = searchList[index];
      if (searchListValue.value === value) {
        return true;
      }
    }
    return disabledValue;
  }

  onKeyPressHandle = (e) => {
    if (e.charCode === 13 || e.key === 'Enter') {
      this.handleSearch();
    }
  }


  static getDerivedStateFromProps = (props) => ({ ...props });

  async initialSetup() {
    const { formatMessage } = this.props.intl;
    let componentPath = this.props.path;
    let customerIdValue = localStorage.getItem("customerId");
    let Option;
    let categoryDisplay;
    if ((componentPath === "/organizationManagement") || (componentPath === "/assignUnitToTopology") || (componentPath === "/assignUserToTopology") || (componentPath === "/assignReportUnitToTopology")|| (componentPath === "/assignMedia") || (componentPath==="/uploadLegacyCookbookToTopology") || (componentPath==="/assignLegacyMenuToTopology") || (componentPath==="/smartTag/smartTagTopology")) {
      Option = await setOptions(this.props.path);
      try {
        categoryDisplay = await http.get(api.organizaionCategoryDetailsUrl,
          {
            headers: {
              'Content-Type': 'application/json',
              customerid: customerIdValue
            },
            data: {}
          });
        if (categoryDisplay.status == 200) {
          this.props.getOrganizationCategoryDetailsNew(categoryDisplay.data);
          Option.map(e => {
            if (e.hasOwnProperty('level_id')) {
              if (Array.isArray(categoryDisplay.data.customer_group_category)) {
                categoryDisplay.data.customer_group_category.map(obj => {
                  if (obj.level_id == e.level_id) {
                    e.name = obj.display_name;
                    e.nameStringID = obj.display_name;
                    e.placeholder = `Search for ${obj.display_name}`;
                    e.placeholderCallback = (currentObj) => { return formatMessage({ id: 'KC1982' }, { name: currentObj.nameStringID }); };
                  }
                });
              }
            }
          });
        }
      } catch (error) {
        console.log("Org Global Category Display Error", error);
      }
    }
    else if(componentPath === "/softwareUpdatesStatus") {
      Option = await setOptions('/softwareUpdatesStatus');
      const enableBakingPlan = localStorage.getItem('enableBakingPlan')
      if(enableBakingPlan == 0) {
        Option = Option.filter(data => data.value !== "searchfilename")
      }
    }
    else {
      if (componentPath === "/libraryList" && localStorage.getItem("brandName") === "GARLAND") {
        Option = await setOptions('/libraryListGarland');
      } else {
        if ((componentPath.toLowerCase() === "/mediapreparation") || (componentPath.toLowerCase() === "/mediascreensaver") || (componentPath.toLowerCase() === "/mediacompilation") || (componentPath.toLowerCase() === "/mediapackage")) {
          Option = await setOptions("/mediaPages");
        } else if ((componentPath === "/mediaImageLibrary") || (componentPath === "/mediaAudioLibrary")) {
          Option = await setOptions("/mediaOtherPages");
        } else if ((componentPath === "/_dashboard/:serialNo/:menuName/:tabName?")) {
          Option = await setOptions("/serviceManagement");
        } else {
          Option = await setOptions(componentPath);
        }
      }
    }
    if (Option) {
      this.setState({ Option, searchList: [Option[0]], categoryList: Option });
    }
  }

  render() {
    const { formatMessage } = this.props.intl;
    let { isActive, isSearch, searchtext, searchList, categoryList } = this.state;
    let searchKeys = [];
    searchKeys = this.getSearchKeys(searchList);

    if (!isSearch) { return false };

    return (
      // Global Search Component
      <React.Fragment>
        <li class="nav-item navSearchToggleBox">
          <div class="input-group headerSearchBox">
            {/* Global Filter Dropdown Button */}
            <div class="input-group-prepend" onClick={() => this.handleActive(!isActive)}>
              <a class={`navAdvSearch ${isActive ? 'active' : ''}`} title={ formatMessage({ id: 'KC0037' }) } href="javascript:void(0)">&nbsp;</a>
            </div>
              {/* Search Box */}
              <input type="text" id="searchBox" onChange={(e) => this.setState({ searchtext: e.target.value, isActive: false })} onKeyPress={this.onKeyPressHandle} value={searchtext} name="searchtext" className="form-control hSearchBox" placeholder={searchKeys.length ? "" : formatMessage({ id: 'KC0037' })} />
              {/* Search Box Clear Button */}
              {searchtext && searchtext.trim() !== "" &&
                <button
                  type="button"
                  onClick={() => {
                    this.setState({ searchtext: '' }, () => {
                      this.props.searchText({ searchtext: '' });
                    });

                  }}
                  className="btn cross-button">&nbsp;
                </button>}

              {/* Search Keys Toaster */}
              <div className="toaster-box">
                {searchKeys && searchKeys.slice(0, 2).map((searchKeyObj, i) =>
                  <div key={i} class="toast" role="alert" aria-live="assertive" aria-atomic="true">
                    <div class="toast-header">
                      <strong class="me-auto" onClick={() => this.handleActive(!isActive)}>{searchKeyObj.nameStringID ? <FormattedMessage id={searchKeyObj.nameStringID}/> : searchKeyObj.name}</strong>
                      <button type="button" onClick={() => this.removeFromToast(searchKeyObj.name)} class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                    </div>
                  </div>
                )}
                {/* Search Keys Count */}
                {searchKeys.length > 2 &&
                  <div class="toast" role="alert" aria-live="assertive" aria-atomic="true">
                    <div class="toast-header">
                      <strong class="me-auto">+ <span>{searchKeys.length - 2}</span></strong>
                    </div>
                  </div>}
              </div>
            {/* Search Button */}
            <button type="button" onClick={() => this.handleSearch()} className="btn searchBxBtn">&nbsp;</button>
          </div>

          {/*  Global Filter list category */}
          <div class={`navSearchDrpdn advance-search-box ${isActive ? 'active' : ''}`} >
            <a href="javascript:void(0)" class="advSearch" title={ formatMessage({ id: 'KC0047' }) }> <FormattedMessage id="KC0047"/> </a>
            {/* Iterate List Dropdown */}
            {searchList && searchList.map((searchItem, i) => (
                <div className={categoryList.length === searchList.length && searchItem.name === "Category" ? "displaynone" : "input-group mb-2"} key={i}>
                <div className="input-group-prepend">
                  {/* Dropdown */}
                  <div class="dropdown">
                    <a class="nav-link dropdownSub dropdown-toggle rightArrow" name={searchItem.name + '-select'} id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      {searchItem.nameStringID ? <FormattedMessage id={searchItem.nameStringID}/> : searchItem.name}
                    </a>
                    <ul class={`dropdown-menu ${this.state.parentLevel === i + searchItem.name ? "show" : ""}`} arialabelledby="navbarDropdownMenuLink">
                      {categoryList && categoryList.map((category, key) => {
                        const isDisabled = category.name === "Category" ? true : this.getCategoryValueIsDisabled(category.value);
                        if(category.name === "Category"){
                          return;
                        }
                        return (
                          !category.childValues.length ?
                            <li key={key} class={`${isDisabled ? "option-disabled" : ""}`}>
                              <a class={`${isDisabled ? "option-disabled-text dropdown-item" : "dropdown-item"}`} value={category.value} onClick={() => isDisabled ? "" : this.onSelectCategory(category, i, searchItem.name === "Category", "parent")}>{category.nameStringID ? <FormattedMessage id={category.nameStringID}/> : category.name}</a>
                            </li> :
                            <li key={key} onClick={() => isDisabled ? '' : this.setState({ childLevel: i + category.name, parentLevel: i + searchItem.name })} class={`${isDisabled ? "dropdown-submenu option-disabled" : "dropdown-submenu"}`}>
                              <a class={`${isDisabled ? "dropdown-toggle dropdown-item option-disabled-text " : "dropdown-toggle dropdown-item "}`}
                              >{category.nameStringID ? <FormattedMessage id={category.nameStringID}/> : category.name}</a>
                              <ul class={`dropdown-menu ${this.state.childLevel === i + category.name ? "show" : ""}`}>
                                {category.childValues.map((child, value) => {
                                  const isChildDisabled = child.name === "Category" ? true : this.getCategoryValueIsDisabled(child.value);
                                  return (
                                    <li key={value} class={`${isChildDisabled ? "option-disabled" : ""}`}><a class={`${isChildDisabled ? "option-disabled-text dropdown-item" : "dropdown-item"}`} value={child.value} onClick={(e) => {e.preventDefault(); isChildDisabled ? this.setState({ childLevel: '', parentLevel: '' }) : this.onSelectCategory(child, i, searchItem.name === "Category", "child")}}>{child.nameStringID ? <FormattedMessage id={child.nameStringID}/> : child.name}</a></li>
                                  )
                                })}
                              </ul>
                            </li>
                        )
                      })}
                    </ul>
                  </div>
                </div>
                <div className="advanced-pos-relative">
                  {searchItem.isDropdown ? 
                    <select id="globalsearchselect" className="form-control hSearchSelect" onChange={(e) => this.onEnterValue(i, e.target.value)}>
                        <option value={""}>{formatMessage({ id: 'KC2131' })}</option>
                        { searchItem.isDropdown.map((item, i) =>
                            <option key={i} value={item.value} className={searchItem.filterValue === item.value ? "option-disabled" : ""}>{item.label}</option>
                        )
                    }
                  </select> 
                  : <input onChange={(e) => this.onEnterValue(i, e.target.value)} value={searchItem.name === "Category" ? "" : searchItem.filterValue} name={searchItem.value} type="text"
                    class="form-control hSearchText no-background-icon" placeholder={searchItem.placeholderCallback ? searchItem.placeholderCallback(searchItem): searchItem.placeholder ? formatMessage({ id: searchItem.placeholder }) : formatMessage({ id: 'KC0037' })} />
                  } 
                  {searchItem.value && <button type="button"
                    onClick={() => this.removeItem(i)} className="btn advanced-cross-button">&nbsp;</button>}
                </div>
              </div>
            ))}
            {/* Filter Search Button */}
            <button
              onClick={() => this.handleSearch()}
              type="button"
              disabled={!searchKeys.length}
              className={`btn searchBtn btn-secondary`}> <FormattedMessage id="KC0037"/>
            </button>
          </div>
        </li>
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    searchText,
    userManagementData,
    getOrganizationCategoryDetailsNew
  }, dispatch);
}

const mapStateToProps = state => {
  return {
    isSearch: state.advanceSearch.isVisible
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(GlobalSearchBar));