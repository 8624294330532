import { useEffect, useReducer } from "react"
import { FormattedMessage } from "react-intl"
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';
import { HACCPManagementAPI } from '../../../service/api';
import http from '../../../service/httpService';
import moment from "moment";
import ErrorMoal from "../../modal/error-model";
import SuccessMoal from "../../modal/success-model";

const PROGRESS_GRAPH = {
    failed: 100,
    queued: 15,
    pending: 65,
    in_progress: 65,
    succeeded: 0,
    reset: 0
}

const initialState = {
    loading: false,
    allowRetrive: false,
    allowDownload: false,
    previousLog: null,
    progressState: 'reset',
    message: "",
    error: false,
    success: false

};

const reducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
        case "SET_PREVIOUS_LOG":
            return { ...state, previousLog: payload }
        case "CHANGE_BUTTON_STATE":
            return { ...state, ...payload }
        case "SHOW_SUCCESS_MODEL":
            return { ...state, error: false, success: true, message: payload }
        case "SHOW_ERROR_MODEL":
            return { ...state, error: true, success: false, message: payload }
        case "CLOSE_MODEL":
            return { ...state, error: false, success: false, message: "" }
        default:
            return { ...state }
    }
};

const DateFormatter = (date) => (moment(date).format('LL') + " - " + moment(date).format('LT'))

const RequestLogButton = ({ disabled, progressState, onClick }) => {

    const _progressState = progressState.toLowerCase();
    const progressPercentage = PROGRESS_GRAPH[_progressState];

    return (
        <button type="button"
            onClick={onClick}
            class={`btn btn-default retrieveBtn ${disabled ? "disabled" : ""} `}>
            <span class="btnIcon retrivalLogBtn"></span>
            <span class="btnText"><FormattedMessage id="KC0246" /> <b><FormattedMessage id="KC0247" /></b></span>
            <span class={`btnProgress ${progressState === 'failed' ? 'retrievFailed' : 'retrieving'}`} style={{ width: `${progressPercentage}%` }}></span>
        </button>
    )
}

const DownloadLogButton = ({ logInfo, disabled, onClick }) => {
    return (
        <button type="button" class={`btn ${disabled ? 'btn-default disabled' : "btn btn-info"}`} onClick={onClick}>
            <span class="btnIcon DownloadRetriveLog"></span>
            <span class="btnText"><FormattedMessage id="KC0248" />
                {disabled && <b>
                    <FormattedMessage id="KC0249" />
                </b>
                }
                {!disabled && logInfo && <b> {DateFormatter(logInfo.REQUEST_DATE)}</b>}
            </span>
            <span class="btnProgress retrieving" ></span>
        </button>
    )
}

const LogDownloader = ({title, currentUnit }) => {
    console.log("currentUnit ", currentUnit)
    const [state, dispatch] = useReducer(reducer, initialState);
    let intervalId;

    useEffect(() => {
        setButtonState();
        return () => clearInterval(intervalId);
    }, [])

    //Get if there is any active log download available
    const loadPreviousRequestLog = async () => {
        const { BASE_COUNTRY, CLOUD_UNIT_NAME, BRAND_ID, CUSTOMER_ID } = currentUnit
        const url = HACCPManagementAPI.downloadFile + "?unitid=" + CLOUD_UNIT_NAME;
        let custGroupId = localStorage.getItem("custGroupID");
        const headers = {
            'Content-Type': 'application/json',
            'basecountry': BASE_COUNTRY,
            'brandid': BRAND_ID,
            'customerid': CUSTOMER_ID,
            'cgids': custGroupId
        }
        let deviceLog;
        try {
            const response = await http.get(url, { headers, data: {} });
            const devices = response.data.devices || [];
            if (devices.length > 0) {
                deviceLog = devices[0];
            }
        } catch (error) {
            console.log("Failed to get previous log data");
        }
        return deviceLog
    }

    const handleLogRequest = async () => {
        const postdata = {
            thingList: [{
                thingArn: currentUnit.THING,
                modelName: currentUnit.MODEL_NAME,
                modelNumber: currentUnit.MODEL_NAME,
                cloudUnitName: currentUnit.CLOUD_UNIT_NAME,
                unitTimeZone: currentUnit.TimeZone_Standard,
            }],
            brandid: currentUnit.BRAND_ID
        }

        const headers = {
            'Content-Type': 'application/json',
            basecountry: currentUnit.BASE_COUNTRY,
            user: localStorage.getItem("user"),
            customerid: currentUnit.CUSTOMER_ID,
        }
        try {
            await http.post(HACCPManagementAPI.requestLog, postdata, { headers });
            dispatch({ type: "SHOW_SUCCESS_MODEL", payload: "KC1841" })
            await setButtonState(true);
            WoopraEvents(`${Constants.RETRIEVE_BACKUP_LOG}`);

        } catch (error) {
            dispatch({ type: "SHOW_ERROR_MODEL", payload: "KC1337" })
        }

    }

    const setButtonState = async (newRequest) => {

        // Set initial states
        let allowRetrive = false;
        let progressState = 'reset';
        let allowDownload = false;

        // when a new request is made
        if(newRequest){
            if (!intervalId) {
                console.log("create interval for new request");
                intervalId = setInterval(setButtonState, 10000);
            }
            return;
        }

        console.log("checking log status");

        //Get if there is any previous log available
        const prevDeviceLog = await loadPreviousRequestLog()
        //prevDeviceLog.status = 'IN_PROGRESS'
        dispatch({ type: "SET_PREVIOUS_LOG", payload: prevDeviceLog })
        //Set it to state for download purpose
        const { CONNECT_STATE, THING } = currentUnit;
        //If device is online and has a valid thing arn

        //Logic to disable retrive log button based on device state
        if (CONNECT_STATE === "Online" && THING) {
            allowRetrive = true
        }
        //Logic to disable retrive log button based previous log state
        if (prevDeviceLog) {
            const logStatus = prevDeviceLog.status.toLowerCase();
            //If there is any active request and not in pending state 
            if (['queued', 'pending', 'in_progress'].includes(logStatus)) {
                allowRetrive = false
                allowDownload = false;
                if (!intervalId) {
                    console.log("create interval");
                    intervalId = setInterval(setButtonState, 10000);
                }
            } else {
                allowDownload = ('succeeded' === logStatus) ? true : false;
                console.log("clearing Interval in setButtonState")
                clearInterval(intervalId);
                intervalId = undefined;
            }
            progressState = logStatus;
        }
        dispatch({
            type: 'CHANGE_BUTTON_STATE', payload: {
                allowRetrive, progressState, allowDownload
            }
        })
    }


    //Download log file
    const handleDownloadLog = () => {
        if (state.previousLog?.S3Url) {
            window.location = state.previousLog.S3Url
            WoopraEvents(`${Constants.DOWNLOAD_BACKUP_LOG}`);
        }
    }

    return (
        <div className={`kc-ui-colm kc-ui-link`}>
            <div className="kc-ui-colm-div">
                <h4><FormattedMessage id={title} /></h4>
                <div className='kc-ui-link-div btnListView'>
                    <ul>
                        <li>
                            <RequestLogButton
                                disabled={!state.allowRetrive}
                                onClick={handleLogRequest}
                                progressState={state.progressState}
                            />
                        </li>
                        <li>
                            <DownloadLogButton
                                logInfo={state.previousLog}
                                disabled={!state.allowDownload}
                                onClick={handleDownloadLog} />
                        </li>
                    </ul>

                </div>
            </div>
            <ErrorMoal open={state.error} message={state.message} stringID={state.message} onCloseModel={() => dispatch({ type: "CLOSE_MODEL" })} />
            <SuccessMoal open={state.success} message={state.message} stringID={state.message} onSuccessModel={() => dispatch({ type: "CLOSE_MODEL" })} />
        </div>
    )
}

export default LogDownloader