import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import UserManagementHeader from '../../layoutSection/userManagementHeader'
import { getSoftwareUpdateStatus } from '../../../actions/navigation/softwareManagement/softwareUpdateStatusActions'
import { TableHeader } from '../../controls/tableHeader';
import _ from 'lodash';
import http from '../../../service/httpService';
import { SoftwareManagementAPI } from '../../../service/api';
import { BRAND_ID, BASECOUNTRY_ID } from '../../../utils/appConstants';
import ErrorMoal from '../../modal/error-model';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import moment from 'moment';
import NoRecordsFound from '../../controls/noRecordsFound';
import { Paginator } from '../../controls/paginator';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';
import { HOVER_TEXT_SCHEDULED, HOVER_TEXT_QUEUED, HOVER_TEXT_UPDATING, HOVER_TEXT_PENDING, HOVER_TEXT_EXPIRED, HOVER_TEXT_COMPLETED, HOVER_TEXT_CANCELED } from '../../../utils/appConstants';
import { FormattedMessage, injectIntl } from 'react-intl';
import { datePickerLocale } from '../../../locale/constant';
import { searchVisible, searchText } from '../../../actions/advanceSearch/advanceSearchAction';
import { isEqualObj, searchData_function } from '../../globalSearchBar/selectOption';
import GoToPagination from '../../controls/goToPagination';
import $ from 'jquery';

let todaysDate = new Date();
let Yesterdaydate = new Date(todaysDate.setDate(todaysDate.getDate()));
let sixMonthsFromNow = new Date(todaysDate.setMonth(todaysDate.getMonth() - 6));
sixMonthsFromNow = sixMonthsFromNow.setDate(sixMonthsFromNow.getDate() - 1);
let savestartDate = new Date(todaysDate.setMonth(todaysDate.getMonth() + 5));
let firstTimeDate = savestartDate;
firstTimeDate = firstTimeDate.setDate(firstTimeDate.getDate() - 1);
let saveendDate = Yesterdaydate;

class SoftwareUpdatesStatus extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loaderIconVisible: false,
			sortColumn: { path: "name", order: "asc", sort_color: "#63778a" },
			error_modal: {
				open: false,
				message: ""
			},
			startDate: localStorage.getItem('startDate') ? moment(localStorage.getItem('startDate')).toDate() : firstTimeDate,
			endDate: localStorage.getItem('endDate') ? moment(localStorage.getItem('endDate')).toDate() : Yesterdaydate,
			maxdate: Yesterdaydate,
			minDate: sixMonthsFromNow,
			currentPage: 1,
			pageSize: 25,
			searchData: null,
			totalPages: null,
			distributionCounts: [],
			Scheduled: true,
			Queued: true,
			Updating: true,
			Pending: true,
			Completed: true,
			Canceled: true,
			Failed: true,
			showBP: 0,
			showToast: false,
			firstLoad: false
		};
	}

	updateDimension() {
		const windowHeight = $(window).height();
		const $tableBody = $('.statusTableSrlBar');
		const $tableBodyBottom = $('.paginationDWgoto, .paginationWgoto').height() ? $('.paginationDWgoto, .paginationWgoto').height() + 18 : 0;
		$tableBody.css('height', windowHeight - ($tableBody.offset().top + $tableBodyBottom));
	}

	componentDidMount() {
		this.setState({
			firstLoad: true
		});
		window.addEventListener('resize', this.updateDimension);
		this.props.searchVisible(true);
		document.body.style.backgroundColor = "#f2f2f2";
		if (localStorage.getItem("brandName") === "CONVOTHERM") this.setState({ showBP: Number(localStorage.getItem("enableBakingPlan")) })
		this.props.getSoftwareUpdateStatus(this.state.startDate, this.state.endDate, this.state.currentPage, this.state.pageSize);
		this.getSoftwareUpdateStatus().catch(err => {
			let { response, message } = err;
			if (response || message) {
				let { data } = response || { data: { message } };
				if (data) {
					//To avoid the two popup at the same time
					if (data.errorCode && data.errorCode === '403100') {
						this.setState({
							loaderIconVisible: false,
						}, () => {
						});
						return;
					}
					this.setState({
						loaderIconVisible: false,
						error_modal: {
							open: true,
							message: data.message ? data.message : ""
						}
					});
				}
			}
			else {
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						open: true,
						message: "KC1083"
					}
				});
			}
		});
		this.interval = setInterval(() => { this.getCount(); this.getSoftwareUpdateStatus() }, 30000);
		this.getCount();
	}

	// Woopra Events
	trackEvent = (e, title = '') => {
		WoopraEvents(`${Constants.SOFTWARE}-${(title || e.target.title)}`)
	}

	getSoftwareUpdateStatus = async (currentPage = this.state.currentPage) => {
		let { searchData, sortColumn } = this.state;
		if (localStorage.getItem('softwareSortColumn') && localStorage.getItem('softwareSortColumn') != sortColumn.path) {
			sortColumn.path = localStorage.getItem('softwareSortColumn');
		}
		this.setState({
			loaderIconVisible: true
		});
		let custGroupID = localStorage.getItem("custGroupID");

		let cgids = "";
		if (custGroupID !== "" && custGroupID !== undefined) {
			cgids = custGroupID;
		}

		// User Story 48542 - Added this to show the selected status items
		let { Scheduled, Queued, Updating, Pending, Completed, Failed, Canceled } = this.state;
		let filtertypeVa = "";
		if (Scheduled) {
			if (filtertypeVa) {
				filtertypeVa = filtertypeVa + "," + "Scheduled";
			} else {
				filtertypeVa = "Scheduled";
			}
		}

		if (Queued) {
			if (filtertypeVa) {
				filtertypeVa = filtertypeVa + "," + "Queued";
			} else {
				filtertypeVa = "Queued";
			}
		}

		if (Updating || Pending) {
			if (filtertypeVa) {
				filtertypeVa = filtertypeVa + "," + "IN_PROGRESS";
			} else {
				filtertypeVa = "IN_PROGRESS";
			}
		}

		if (Completed) {
			if (filtertypeVa) {
				filtertypeVa = filtertypeVa + "," + "SUCCEEDED";
			} else {
				filtertypeVa = "SUCCEEDED";
			}
		}

		if (Canceled) {
			if (filtertypeVa) {
				filtertypeVa = filtertypeVa + "," + "CANCELED";
			} else {
				filtertypeVa = "CANCELED";
			}
		}

		if (Failed) {
			if (filtertypeVa) {
				filtertypeVa = filtertypeVa + "," + "REJECTED,FAILED";
			} else {
				filtertypeVa = "REJECTED,FAILED";
			}
		}

		let url = `${SoftwareManagementAPI.softwareUpdateStatusUrl}`;

		//searchData_function
		let _search = await searchData_function(searchData);
		_search = _search != undefined ? _search.slice(1) : _search;
		if (_search) {
			url = `${url}?${_search}`;
		}
		if (sortColumn && sortColumn.path) {
			if (_search == undefined || _search == "") {
				url = `${url}?sortOrder=${sortColumn.order}&sortBy=${sortColumn.path}`
			} else {
				url = `${url}&sortOrder=${sortColumn.order}&sortBy=${sortColumn.path}`
			}
		}

		let pageNumber = '&pageNum=' + currentPage;
		let pageLimit = '&pageSize=' + this.state.pageSize;

		url = url + pageNumber + pageLimit;

		return new Promise((resolve, reject) => {
			http.get(url, {
				headers: {
					'Content-Type': 'application/json',
					'customerId': localStorage.getItem("customerId"),
					'brandId': BRAND_ID,
					'cgids': cgids,
					// 'basecountry': BASECOUNTRY_ID,
					"filtertype": filtertypeVa ? filtertypeVa : "UNSELECTALL",
					'startdate': this.state.startDate ? format(this.state.startDate, 'YYYY-MM-DD') : "",
					'enddate': this.state.endDate ? format(this.state.endDate, 'YYYY-MM-DD') : "",
				},
				data: {}
			}).then(response => {
				if (response && response.data && response.data.Software_Distribute_List && response.data.Software_Distribute_List.softwareDistributeList && response.data.Software_Distribute_List.softwareDistributeList.length > 0) {
					response.data.Software_Distribute_List.softwareDistributeList.map((data) => {
						// id => Baking Plan ID, SOFTWARE_FK => Software ID
						if (data.id) {
							data.SOFTWARE_FK = data.id;
							data.FILE_NAME = data.baking_plan_file_name;
							data.FILE_SIZE = data.file_size;
							data.TYPE = data.type;
						}
						return data;
					})
				}
				this.setState({
					loaderIconVisible: false,
					softwareUpdateStatusInformation: response.data.Software_Distribute_List,
					totalPages: response.data.Software_Distribute_List && response.data.Software_Distribute_List.numberOfPages ? response.data.Software_Distribute_List.numberOfPages : 0,
					currentPage
				}, () => {
					if (this.props.location.state && this.props.location.state.showToast && this.state.firstLoad) {
						this.setState({ showToast: true, firstLoad: false });
						setTimeout(() => {
							this.setState({ showToast: false });
						}, 5000);
					}
				});
				resolve(response.data);
			}).catch(err => {
				this.setState({
					loaderIconVisible: false,
					softwareUpdateStatusInformation: [],
					totalPages: null
				});
				reject(err);
			});
		});
	}

	// User Story 48542 - Added getcount to update the count of items per status of all pages
	getCount = async () => {
		this.setState({ loaderIconVisible: true });
		let { searchData } = this.state;

		let custGroupID = localStorage.getItem("custGroupID");
		let cgids = "";
		if (custGroupID !== "" && custGroupID !== undefined) {
			cgids = custGroupID;
		}

		let filtertypeVa = "Scheduled,Queued,IN_PROGRESS,SUCCEEDED,REJECTED,FAILED,CANCELED";

		let headerVal = {
			'Content-Type': 'application/json',
			// 'basecountry': BASECOUNTRY_ID,
			'customerId': localStorage.getItem("customerId"),
			'brandId': BRAND_ID,
			'cgids': cgids,
			"filtertype": filtertypeVa,
			'startdate': this.state.startDate ? format(this.state.startDate, 'YYYY-MM-DD') : "",
			'enddate': this.state.endDate ? format(this.state.endDate, 'YYYY-MM-DD') : "",
		};

		let url = `${SoftwareManagementAPI.softwareUpdateStatusUrl}`;
		//searchData_function
		let _search = await searchData_function(searchData);
		_search = _search != undefined ? _search.slice(1) : _search;
		if (_search) {
			url = `${url}?${_search}`;
		}
		http.get(url, {
			headers: headerVal,
			data: {}
		}).then(response => {
			if (response && response.data) {
				this.setState({
					loaderIconVisible: false,
					distributionCounts: response.data.Software_Distribute_List.statusCount,
				});
			}
		}).catch(err => {
			this.setState({
				loaderIconVisible: false,
				distributionCounts: []
			});
		});
	}

	componentWillUnmount() {
		clearInterval(this.interval);
		this.props.searchText({});
		this.props.searchVisible(false);
		localStorage.removeItem('startDate');
		localStorage.removeItem('endDate');
		localStorage.removeItem('softwareSortColumn');
	}

	static getDerivedStateFromProps = (props) => ({ ...props });

	componentDidUpdate(prevState) {
		this.updateDimension();
		let { searchData } = prevState;
		let curSearchData = this.state && this.state.searchData;
		if (curSearchData && (isEqualObj(searchData, curSearchData) === false)) {
			this.setState({
				currentPage: 1
			}, () => {
				this.getSoftwareUpdateStatus(1);
				this.getCount();
			})
		}
	}

	onSort = sortColumn => {
		localStorage.setItem('softwareSortColumn', sortColumn && sortColumn.path);
		this.setState({
			sortColumn: sortColumn,
		});
		this.getSoftwareUpdateStatus(1);
	}

	closeModel = (err) => {
		this.setState({
			loaderIconVisible: false,
			error_modal: {
				open: false,
				message: ""
			}
		});
	}

	ResetDatepicker = (e) => {
		e.preventDefault();
		return false;
	}

	handleChangestart = (date) => {
		savestartDate = date;
		this.setState({
			startDate: date,
		}, () => {

			localStorage.setItem('startDate', date);
			if (savestartDate > saveendDate) {
				this.setState({
					error_modal: {
						open: true,
						message: "KC1581"
					}
				});
			} else {
				this.getSoftwareUpdateStatus(1);
				this.props.getSoftwareUpdateStatus(date, this.state.endDate, this.state.currentPage, this.state.pageSize)
				this.getCount();
			}
		});

	}

	handleChangeend = (date) => {
		saveendDate = date;
		this.setState({
			endDate: date,
		}, () => {
			localStorage.setItem('endDate', date);
			if (savestartDate > saveendDate) {
				this.setState({
					error_modal: {
						open: true,
						message: "KC1120"
					}
				});
			} else {
				WoopraEvents(`${Constants.DATE_APPLIED}`);
				this.getSoftwareUpdateStatus(1);
				this.props.getSoftwareUpdateStatus(this.state.startDate, date, this.state.currentPage, this.state.pageSize)
				this.getCount();
			}
		});

	}

	getArrayDifferences(result1, result2) {
		//Find values that are in result2 but not in result1
		var uniqueResultTwo = result2.filter(function (obj) {
			return !result1.some(function (obj2) {
				return obj.ID == obj2.ID;
			});
		});

		return uniqueResultTwo;
	}

	/**
	 * When navigate new page this event will return current page number
	 * @param {*} currentPage 
	 */
	onPageChange = (currentPage) => {
		this.setState({ loaderIconVisible: true }, async () => {
			window.scrollTo({
				top: 0,
				behavior: "smooth"
			});
			await this.getSoftwareUpdateStatus(currentPage);
			this.setState({ loaderIconVisible: false })
		});
	}

	// User Story 48542 - Added this to show the selected status items
	checkBoxHandler = (e) => {
		let isChecked = e && e.target && e.target.checked;
		let selCheckboxName = e && e.target && e.target.value;
		this.setState({
			[e.target.value]: isChecked,
		}, () => {
			this.getSoftwareUpdateStatus(1)
		})
	}
	
	closeToast = () => {
		this.setState({ showToast: false });
	}

	render() {
		const { formatMessage } = this.props.intl;
		const { softwareUpdateStatusInfo } = this.props
		let { loaderIconVisible, sortColumn, error_modal, totalPages, currentPage, pageSize, distributionCounts, softwareUpdateStatusInformation } = this.state;
		let updatequeued = 0;
		let updating = 0;
		let updatecomplete = 0;
		let updatefailed = 0;
		let scheduled = 0;
		let pending = 0;
		let updatecanceled = 0;
		let { Scheduled, Queued, Updating, Pending, Completed, Failed, Canceled, showBP } = this.state;
		let columns = [
			{ path: "", label: "#" },
			{ path: "BRAND", labelStringID: "KC0062" },
			{ path: "MODEL_NAME", labelStringID: "KC0064" },
			{ path: "UNITID", labelStringID: "KC0104" },
			{ path: "locationName", labelStringID: "KC0050" },
			{ path: "within_location", labelStringID: "KC0061" },
			{ path: "status", labelStringID: "KC0523" },
			{ path: "", label: "", isNbSp: true },
			{ path: "", label: "", isNbSp: true },
			{ path: "", label: "", isNbSp: true },
		];

		let softwareUpdateStatusList;
		softwareUpdateStatusList = softwareUpdateStatusInformation && softwareUpdateStatusInformation.Software_Distribute_List &&
		softwareUpdateStatusInformation.Software_Distribute_List.softwareDistributeList && softwareUpdateStatusInformation.Software_Distribute_List.softwareDistributeList.length > 0 ? softwareUpdateStatusInformation.Software_Distribute_List.softwareDistributeList : [];
		if (softwareUpdateStatusInformation && softwareUpdateStatusInformation.softwareDistributeList) {
			let difference;
			if (softwareUpdateStatusInformation.softwareDistributeList.length != softwareUpdateStatusList.length) {
				difference = softwareUpdateStatusInformation.softwareDistributeList;
			} else {
				difference = this.getArrayDifferences(softwareUpdateStatusInformation.softwareDistributeList, softwareUpdateStatusList);
			}
			if (difference && difference.length > 0) {
				softwareUpdateStatusList.splice(0, softwareUpdateStatusList.length, ...softwareUpdateStatusInformation.softwareDistributeList);
			}
		}
		else if (softwareUpdateStatusInformation && softwareUpdateStatusInformation.length == 0) {
			softwareUpdateStatusList.splice(0, softwareUpdateStatusList.length, ...softwareUpdateStatusInformation);
		}
		if (distributionCounts) {
			scheduled = distributionCounts.Scheduled ? distributionCounts.Scheduled : 0;
			updatequeued = distributionCounts.Queued ? distributionCounts.Queued : 0;
			updating = distributionCounts.IN_PROGRESS ? distributionCounts.IN_PROGRESS : 0;
			pending = distributionCounts.IN_PROGRESS ? distributionCounts.IN_PROGRESS : 0;
			updatecomplete = distributionCounts.SUCCEEDED ? distributionCounts.SUCCEEDED : 0;
			updatefailed = distributionCounts.FAILED ? distributionCounts.FAILED : 0;
			updatecanceled = distributionCounts.CANCELED ? distributionCounts.CANCELED : 0;
		}

		softwareUpdateStatusList.forEach(item => {
			item.status = item.DISTRIBUTION_STATE === 'FAILED' ? "expired" : item.DISTRIBUTION_STATE === 'REJECTED' ? "expired" : item.DISTRIBUTION_STATE === 'SUCCEEDED' ? "completed" : item.DISTRIBUTION_STATE === 'Scheduled' ? "scheduled" : item.DISTRIBUTION_STATE === 'CANCELED' ? 'canceled' : item.DISTRIBUTION_STATE === 'IN_PROGRESS' ? item.DISTRIBUTION_PROGRESS >= 50 ? "pending" : "updating" : "queued"			
		});

		softwareUpdateStatusList = _.orderBy(softwareUpdateStatusList, [sortColumn.path], [sortColumn.order]);
		let rowNumber = (currentPage * pageSize) - pageSize;

		return (
			<React.Fragment>
				<div id="adminWrapper">
					{/* <!-- Loading Progress --> */}
					<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
						<div className="loadingProgressIn"></div>
					</div>
					{/* <!-- //Loading Progress -->*/}
					<div id="page-content-wrapper">

						<div className="softwareUpdateWrapper">

							<div className="softwareUpdateHeader">
								<ul>
									<li>
										<Link to="/softwareAvailableUpdates" title={formatMessage({ id: 'KC0830' })} onClick={(e) => this.trackEvent(e, 'Available Updates')}><FormattedMessage id="KC0830" /></Link>
										{showBP ? <Link to="/bakingPlan" title={formatMessage({ id: 'KC2345' })} onClick={(e) => this.trackEvent(e, 'Baking Plan')}><FormattedMessage id="KC2345" /></Link> : ''}
										<Link to="#" title={formatMessage({ id: 'KC0659' })} className="active"><FormattedMessage id="KC0659" /></Link>
									</li>
								</ul>
							</div>
							<div className="selectedUnitHeader">
								<ul>
									<li>
										<div className="checkBoxListGrp">
											<div className="customCheckbox crticalCBox">
												<input onClick={(e) => this.checkBoxHandler(e)} id="Scheduled" type="checkbox" value="Scheduled" checked={Scheduled} />
												<label for="Scheduled" title={formatMessage({ id: 'KC1727' })}><FormattedMessage id="KC0573" /> <span> {scheduled}</span></label>
											</div>
											<div className="customCheckbox nonCrticalCBox">
												<input onClick={(e) => this.checkBoxHandler(e)} id="Queued" type="checkbox" value="Queued" checked={Queued} />
												<label for="Queued" title={formatMessage({ id: 'KC1728' })}><FormattedMessage id="KC0691" /> <span>{updatequeued}</span></label>
											</div>
											<div className="customCheckbox">
												<input onClick={(e) => this.checkBoxHandler(e)} id="Updating" type="checkbox" value="Updating" checked={Updating} />
												<label for="Updating" title={formatMessage({ id: 'KC1729' })}><FormattedMessage id="KC0692" /> <span>{updating}</span></label>
											</div>
											<div className="customCheckbox">
												<input onClick={(e) => this.checkBoxHandler(e)} id="Pending" type="checkbox" value="Pending" checked={Pending} />
												<label for="Pending" title={formatMessage({ id: 'KC1742' })}><FormattedMessage id="KC0693" /> <span>{pending}</span></label>
											</div>
											<div className="customCheckbox">
												<input onClick={(e) => this.checkBoxHandler(e)} id="Failed" type="checkbox" value="Failed" checked={Failed} />
												<label for="Failed" title={formatMessage({ id: 'KC1730' })}><FormattedMessage id="KC0359" /> <span>{updatefailed}</span></label>
											</div>
											<div className="customCheckbox">
												<input onClick={(e) => this.checkBoxHandler(e)} id="Completed" type="checkbox" value="Completed" checked={Completed} />
												<label for="Completed" title={formatMessage({ id: 'KC1731' })}><FormattedMessage id="KC0324" /> <span>{updatecomplete}</span></label>
											</div>
											<div className="customCheckbox">
												<input onClick={(e) => this.checkBoxHandler(e)} id="Canceled" type="checkbox" value="Canceled" checked={Canceled} />
												<label for="Canceled" title={formatMessage({ id: 'KC1732' })}><FormattedMessage id="KC0694" /> <span>{updatecanceled}</span></label>
											</div>
										</div>
									</li>
									<li>
										<div className="dateRangeForm">
											<span><FormattedMessage id="KC0695" />:</span>
											<DatePicker locale={datePickerLocale} autoComplete="off" id="startdate" showMonthDropdown showYearDropdown dropdownMode="select" onKeyDown={(e) => this.ResetDatepicker(e)} selected={this.state.startDate} onChange={this.handleChangestart} minDate={this.state.minDate} maxDate={this.state.maxdate} />
											<span><FormattedMessage id="KC0696" />:</span>
											<DatePicker locale={datePickerLocale} popperModifiers={{ preventOverflow: { enabled: true, }, }} autoComplete="off" id="enddate" showMonthDropdown showYearDropdown dropdownMode="select" onKeyDown={(e) => this.ResetDatepicker(e)} selected={this.state.endDate} onChange={this.handleChangeend} minDate={this.state.minDate} maxDate={this.state.maxdate} />
										</div>
									</li>
								</ul>
							</div>

							<div className="updateStatusTableOuter">
								<div className="updateStatusTable">
									<TableHeader
										userMgmtListScreen={true}
										sortColumn={sortColumn}
										onSort={this.onSort}
										columns={columns} />
								</div>
								<div className='statusTableSrlBar'>
									<div className="panel panel-default" id="availableUpdatesAccordion" role="tablist" aria-multiselectable="true">
										{/* <!-- Header 01 --> */}
										{softwareUpdateStatusList.map((item, i) => {
											let WINDOW_MODELFAMILY_COLUMN = item.MODEL_FAMILY_NAME === "eikon® e1s" ? (<li>eikon<sup>®</sup> e1s</li>) : item.MODEL_FAMILY_NAME === "eikon® e2s" ? (<li>eikon<sup>®</sup> e2s</li>) : item.MODEL_FAMILY_NAME === "eikon® e4s" ? (<li>eikon<sup>®</sup> e4s</li>) : (<li>{item.MODEL_FAMILY_NAME}</li>)
											let MOBILE_MODELFAMILY_COLUMN = item.MODEL_FAMILY_NAME === "eikon® e1s" ? (<span>eikon<sup>®</sup> e1s</span>) : item.MODEL_FAMILY_NAME === "eikon® e2s" ? (<span>eikon<sup>®</sup> e2s</span>) : item.MODEL_FAMILY_NAME === "eikon® e4s" ? (<span>eikon<sup>®</sup> e4s</span>) : (<span>{item.MODEL_FAMILY_NAME}</span>)
											return (
												<React.Fragment key={i}>
													<div className="updateStatusTable">
														<ul className="tBody">
															<li>{rowNumber + i + 1}</li>
															<li>{item.BRAND}
																<div className="updateStatusSM">{item.MODEL_NAME}</div>
																<div className="updateStatusSB">
																	<div className="progress">
																		<div className={item.DISTRIBUTION_STATE === 'FAILED' ? "progress-bar warningStatus" : item.DISTRIBUTION_STATE === 'REJECTED' ? "progress-bar warningStatus" : "progress-bar updatingSW"} style={{ width: item.DISTRIBUTION_PROGRESS + '%' }}></div>
																	</div>
																</div>
																<div className="updateStatusSB">{item.status}</div>
															</li>
															<li>{item.MODEL_NAME}</li>
															<li>{item.UNITID}</li>
															<li>{item.locationName}</li>
															<li>{item.within_location}</li>
															<li>{item.status}</li>
															<li>
																<img src={item.DISTRIBUTION_STATE === 'FAILED' ? require('../../../images/icons/ErrorD.svg').default : item.DISTRIBUTION_STATE === 'REJECTED' ? require('../../../images/icons/ErrorD.svg').default : item.DISTRIBUTION_STATE === 'Scheduled' ? require('../../../images/icons/clockS.svg').default : item.DISTRIBUTION_STATE === 'SUCCEEDED' ? require('../../../images/icons/confirmGreen.svg').default : item.DISTRIBUTION_STATE.toUpperCase() === 'CANCELED' ? require('../../../images/icons/cancelIcon.png') : require('../../../images/icons/UpdateD.svg').default} alt="" />
															</li>
															<li>
																<div className="progress">
																	<div className={item.DISTRIBUTION_STATE === 'FAILED' ? "progress-bar warningStatus" : item.DISTRIBUTION_STATE === 'REJECTED' ? "progress-bar warningStatus" : "progress-bar updatingSW"} style={{ width: item.DISTRIBUTION_PROGRESS + '%' }}></div>
																</div>
															</li>
															<li><button role="button" data-toggle="collapse" draggable="false" data-parent="#availableUpdatesAccordion" data-target={`#availableUpdatesAcc` + item.ID} aria-expanded="false" aria-controls="availableUpdatesAcc1" className="btn collapseExpand collapsed">&nbsp;</button></li>
														</ul>
													</div>
													{/* <!-- Header 01 Content --> */}
													<div id={`availableUpdatesAcc` + item.ID} className="panel-collapse collapse" role="tabpanel" aria-labelledby={`availableUpdatesAccH` + item.ID}>
														<div className="panel-body updateStatusExpTable">
															<ul className="tBody">
																<li>&nbsp;</li>
																<li>{item.SOFTWARE_EXIST && item.SOFTWARE_EXIST === "true" && <div className="installedText"><FormattedMessage id="KC2299" /></div>}
																	<b><FormattedMessage id="KC0833" />:</b> {item.MODEL_FAMILY_NAME}
																	<div>{item.DISTRIBUTION_DATE_TIME}</div>
																</li>
																<li>{item.DESCRIPTION}
																	{/* This Update includes bug fixes for your Crem Carrara Espresso.<br/> 
For information on the security content of Crem software updates,<br/> please visit this website: <Link to="https://support.crem.com" target="_blank">https://support.crem.com</Link> */}
																</li>
															</ul>
														</div>
													</div>
												</React.Fragment>
											)
										})
										}
									</div>
									<NoRecordsFound loaderIconVisible={loaderIconVisible} length={softwareUpdateStatusList !== undefined ? softwareUpdateStatusList.length : 0} classname={'norecordsfoundbluetext'} />
								</div>
								{totalPages && totalPages > 1 ?
									<GoToPagination
										totalPageNumber={this.state.totalPages}
										pageNumber={this.state.currentPage}
										navigateTo={(p) => this.onPageChange(p)} /> : null}
							</div>

						</div>
						<ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
					</div>
					<UserManagementHeader headerName="Software Management" headerNameStringID="KC0633" activeClass="softwareNav" />
					{/* Toast Notification */}
					{this.state.showToast && (
						<div className="alert toasMsgAlert alert-dismissible fade show" role="alert">
							<span className="toasMsg">{formatMessage({ id: "KC2928" }).replace("{{number}}",this.props.location.state.totalUnitsCount)}</span>
							<button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={this.closeToast}>&nbsp;</button>
						</div>
					)}
				</div>
			</React.Fragment>

		)
	}
};

function mapStateToProps(state) {
	return {
		softwareUpdateStatusInfo: state.softwareUpdateStatusInformation.softwareUpdateStatusInformation,
		searchData: state.advanceSearch.searchData,
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		getSoftwareUpdateStatus, searchVisible, searchText
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SoftwareUpdatesStatus));
