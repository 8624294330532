import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { ReportManagement, UnitInfo } from '../../../service/api';
import http from '../../../service/httpService';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { BRAND_ID, twelvehourArray, twentyfourhourArray, CUSTOMER_ID } from '../../../utils/appConstants';
import * as momentFormat  from 'moment';
import { injectIntl,FormattedMessage } from 'react-intl';
import { datePickerLocale } from '../../../locale/constant';
import { connect } from 'react-redux';

let todaysDate = new Date();
let threeMonthsFromNow = new Date(todaysDate.setMonth(todaysDate.getMonth() - 3));
let scheduledMaxDate = new Date(todaysDate.setFullYear(todaysDate.getFullYear() + 100));
let _isNavigateBack = false;
$(function () {
	$('#fromdate').on('focus', function (e) {
		e.preventDefault();
		$(this).blur();
	});
	$('#todate').on('focus', function (e) {
		e.preventDefault();
		$(this).blur();
	});
});

class newReportWithOptions extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loaderIconVisible: false,
			reportNameErrorMsg: '',
			startDateErrorMsg: '',
			endDateErrorMsg: '',
			lastDaysErrorMsg: '',
			emailErrorMsg: '',
			commentErrorMsg: '',
			fromdate: '',
			todate: '',
			maxdate: '',
			minDate: '',
			reportName: "",
			brandDropDownSource: [],
			brandId: 0,
			brandUnitLimit: 0,
			optionsDropDownSource: [],
			generateNow: true,
			scheduleReport: false,
			recipientsEmail: '',
			comment: '',
			isBrandListLoaded: false,
			isOptionsListLoaded: false,
			isRecipientsValid: true,
			reportType: '',
			hourFormat: 'true',
			scheduledHourValue: '',
			scheduledMinsValue: '',
			noEndDate: false, 
			endDate: false,
			lastDays: false,
			currentDays: false,
			lastDaysPeriod: 0,
			selectedRepetitionPattern: "", 
            selectedLastPeriod: "daily",
			selectedCurrentPeriod: "weekly",
			AMPMformat: "AM",
			isEdit: false,
			hourArray: twelvehourArray
		};
		this.ResetDatepicker = this.ResetDatepicker.bind(this);
		this.getBrandList = this.getBrandList.bind(this);
		this.NameChange = this.NameChange.bind(this);
		this.OnBrandChange = this.OnBrandChange.bind(this);
		this.setGenerateType = this.setGenerateType.bind(this);
		this.setEndDatetype = this.setEndDatetype.bind(this);
		this.setDataPeriodtype = this.setDataPeriodtype.bind(this);
		this.isOptionSelectationChange = this.isOptionSelectationChange.bind(this);
		this.LastDaysChange = this.LastDaysChange.bind(this);
		this.setBrandList = this.setBrandList.bind(this);
	}

	componentDidMount = () => {
		document.body.style.backgroundColor = "#F2F2F2";
		this.setState({
			hourFormat: localStorage.getItem("timeFormat") === "24" ? "false" : "true",
			hourArray: localStorage.getItem("timeFormat") === "24" ? twentyfourhourArray : twelvehourArray
		});
		let values = this.props.location.state;
		let reportType = values && values.reportType ? values.reportType : 0;
		if (reportType) {
			this.setState({
				generateNow: false,
				scheduleReport: true,
				maxdate: scheduledMaxDate,
				minDate: new Date()
			});
			$("#ScheduleReportView").css("display","block"); 
			$("#GenerateNowView").css("display","none");
			$("#scheduledRecipientsEmail").css("display","block"); 
			$("#scheduledComment").css("display","block"); 
		}
		else if (!reportType) {
			this.setState({
				scheduleReport: false,
				generateNow: true,
				maxdate: new Date(),
				minDate: threeMonthsFromNow
			});
			$("#GenerateNowView").css("display","block"); 
			$("#ScheduleReportView").css("display","none");
			$("#scheduledRecipientsEmail").css("display","none"); 
			$("#scheduledComment").css("display","none"); 
		}
		if (values && values.endDate) {
			$("#endDateDiv").css("display","block"); 
		}
		else {
			$("#endDateDiv").css("display","none"); 
		}
	}

	async componentWillMount() {
		if (this.props.location && this.props.location.state) {
			let values = this.props.location.state;
			_isNavigateBack = this.props && this.props.history.action === "PUSH" ? true : false;
			this.setState({
				reportName: values.nameFromReport ? values.nameFromReport : "",
				fromdate: values.startDateFromReport 
							? values.startDateFromReportFormat? momentFormat(values.startDateFromReport, values.startDateFromReportFormat).toDate(): momentFormat(values.startDateFromReport).toDate()
							: values.reportType === 1 ? new Date() : "",
				todate: values.endDateFromReport ? momentFormat(values.endDateFromReport).toDate() : "",
				brandId: localStorage.getItem("selectedbrandID") ? parseInt(localStorage.getItem("selectedbrandID")) : 0,
				brandUnitLimit: localStorage.getItem("selectedBrandUnitLimit") ? parseInt(localStorage.getItem("selectedBrandUnitLimit")) : 0,
				optionsDropDownSource : values && values.selectedoptionsFromReport ? values.selectedoptionsFromReport : [],
				generateNow: values.generateNow,
				scheduleReport: values.scheduleReport,
				recipientsEmail : values.recipientsEmail,
				comment : values.comment,
				reportType: values.reportType,
				selectedRepetitionPattern: values.selectedRepetitionPattern,
				selectedLastPeriod: values.selectedLastPeriod ? values.selectedLastPeriod : "daily",
				selectedCurrentPeriod: values.selectedCurrentPeriod ? values.selectedCurrentPeriod : "weekly",
				lastDays: values.currentDays ? false : true,
				currentDays: values.currentDays ? true : false,
				lastDaysPeriod: values.lastDaysPeriod ? values.lastDaysPeriod : "",
				hourFormat: values.hourFormat ? values.hourFormat : false,
				scheduledHourValue: values.scheduledHourValue ? values.scheduledHourValue : "",
				scheduledMinsValue: values.scheduledMinsValue ? values.scheduledMinsValue : "",
				noEndDate: values.endDate ? false : true,
				endDate: values.endDate ? true : false,
				AMPMformat: values.AMPMformat ? values.AMPMformat : "",
				isEdit: values.isEdit,
				id: values.id,
				schedule_id: values.schedule_id,
				checkedUnits: values.checkedUnits ? values.checkedUnits : [],
				selectedGroupName: values.selectedGroupName,
				selectedDynasty: values.selectedDynasty,
				brandListResponseMessage: values.brandListResponseMessage,
				loaderIconVisible: true
			})
			if (values.reportType === 1) {
				this.setState({
					maxdate: scheduledMaxDate,
					minDate: new Date()
				});
			}
			else if (values.reportType === 0) {
				this.setState({
					maxdate: new Date(),
					minDate: threeMonthsFromNow
				});
			}
			await this.setBrandList();
			await this.getBrandList();
			
		}
	}
	
	getBrandList = async () => {
		await http.get(ReportManagement.getReportBrandList, {
			headers: {
				'Content-Type': 'application/json',
				'allowedbrands': BRAND_ID
			},
			data: {}
		}).then(async response => {
			let defaultBrandList = [];
			let availableBrandList = JSON.parse(localStorage.getItem("availableUnitsBrand"))
			let initialDefaultBrandList = response.data.branList;
			const sortedBrandList = _.sortBy(initialDefaultBrandList, ['BRAND_NAME']);
			for(let i = 0; i < sortedBrandList.length ; i++ ){
				for(let j = 0 ; j < availableBrandList.length ; j++ ){
					if(sortedBrandList[i].BRAND_ID === availableBrandList[j].BRAND_ID){
						defaultBrandList.push(sortedBrandList[i]);
					}
				}
			}
			console.log(defaultBrandList)
			if(defaultBrandList && defaultBrandList.length === 1){
				let brandIdValue = parseInt(defaultBrandList[0].BRAND_ID);
				let brandUnitLimit = defaultBrandList[0].limit;
				brandUnitLimit = brandUnitLimit? parseInt(brandUnitLimit): 0;
				this.setState({
					brandId: brandIdValue,
					brandUnitLimit,
					// lastDaysPeriod: "",
					lastDaysErrorMsg: "",
					brandDropDownSource : defaultBrandList,
					isBrandListLoaded: true
				});
				localStorage.setItem("selectedbrandID", brandIdValue);
				localStorage.setItem("selectedBrandUnitLimit", brandUnitLimit);
				await http.get(ReportManagement.getBrandOptions, {
					headers: {
						'Content-Type': 'application/json',
						'brandid': brandIdValue
					},
					data: {}
				}).then(response => {
					let optionsData = response.data.data; 
					optionsData && optionsData.length > 0 && optionsData.map((item,i) => {
						if (item.is_required ) {
							item.checked = true;
							item.disabled = true;
						}
					});
					this.setState({
						optionsDropDownSource : optionsData,
						checkedUnits: [],
						selectedGroupName: [],
						selectedDynasty: [],
						loaderIconVisible: false
					});
					// localStorage.setItem("selectedUnitID", "") ;
				}).catch(err => {
					//Error Goes Here
					this.setState({
						loaderIconVisible: false
					   });
				});
			}else{
				localStorage.setItem("availableBrands", defaultBrandList);
				if(localStorage.getItem("selectedbrandID") && localStorage.getItem("selectedbrandID") != "") {
					this.generateOptionsOnRefresh();
				}
				this.setState({
					brandId: localStorage.getItem("selectedbrandID") ? parseInt(localStorage.getItem("selectedbrandID")) : '',
					// lastDaysPeriod: localStorage.getItem("lastDaysPeriod") ? parseInt(localStorage.getItem("lastDaysPeriod")) : "",
					lastDaysErrorMsg: "",
					brandDropDownSource : defaultBrandList,
					loaderIconVisible: false,
					isBrandListLoaded: true
				});
			}
		}).catch(err => {
			this.setState({
				loaderIconVisible: false
		   	});
		});
	} 

	generateOptionsOnRefresh = async () => {
		let { optionsDropDownSource } = this.state;
		let brandIdValue = localStorage.getItem("selectedbrandID")
		if ( _isNavigateBack && optionsDropDownSource && optionsDropDownSource.length > 1  && ('' + optionsDropDownSource[0].brand_id + '' === brandIdValue) ) {
			this.setState({
				optionsDropDownSource: optionsDropDownSource,
				isOptionsListLoaded: true,
				checkedUnits: [],
				selectedGroupName: [],
				selectedDynasty: [],
				loaderIconVisible: false
			});
			// localStorage.setItem("selectedUnitID", "");
		} else {
			let brandIdValue = localStorage.getItem("selectedbrandID")
			await http.get(ReportManagement.getBrandOptions, {
				headers: {
					'Content-Type': 'application/json',
					'brandid': brandIdValue
				},
				data: {}
			}).then(response => {
				let optionsData = response.data.data;
				optionsData && optionsData.length > 0 && optionsData.map((item, i) => {
					if (item.is_required ) {
						item.checked = true;
						item.disabled = true;
					}
				});
				this.setState({
					optionsDropDownSource: optionsData,
					isOptionsListLoaded: true,
					checkedUnits: [],
					selectedGroupName: [],
					selectedDynasty: [],
					loaderIconVisible: false
				});
				// localStorage.setItem("selectedUnitID", "");
			}).catch(err => {
				//Error Goes Here
				this.setState({
					loaderIconVisible: false
				});
			});
		}
	}

	OnBrandChange = (e) => {
		let brandIdValue = parseInt(e.target.value);
		let brandUnitLimit = e.target.selectedOptions[0].getAttribute('data-brand-unit-limit');
		brandUnitLimit = brandUnitLimit? parseInt(brandUnitLimit): 0;
		this.setState({
			loaderIconVisible: true,
			brandId: brandIdValue,
			brandUnitLimit,
			lastDaysPeriod: "",
			lastDaysErrorMsg: ""
	    });
	    localStorage.setItem("selectedbrandID", brandIdValue);
		localStorage.setItem("selectedBrandUnitLimit", brandUnitLimit);
		http.get(ReportManagement.getBrandOptions, {
			headers: {
				'Content-Type': 'application/json',
				'brandid': brandIdValue
			},
			data: {}
		}).then(response => {
			let optionsData = response.data.data;
			optionsData && optionsData.length > 0 && optionsData.map((item,i) => {
				if (item.is_required ) {
					item.checked = true;
					item.disabled = true;
				}
			});
			this.setState({
				optionsDropDownSource : optionsData,
				isOptionsListLoaded: true,
				checkedUnits: [],
				selectedGroupName: [],
				selectedDynasty: [],
				loaderIconVisible: false,
				brandListResponseMessage: response.data.message
			});
			localStorage.setItem("selectedUnitID", "") ;
		}).catch(err => {
			//Error Goes Here
			this.setState({
				loaderIconVisible: false
		   	});
		});
	}

	handleChangestart = (date) => {
		const { generateNow, endDate, todate } = this.state;
		this.setState({
			fromdate: date
		});
		if (date > todate && generateNow && todate) {
			this.setState({
				startDateErrorMsg: "KC1581",
				endDateErrorMsg: ""
			});
		}
		else if (date > todate && endDate && todate) {
			this.setState({
				startDateErrorMsg: "KC1581",
				endDateErrorMsg: ""
			});
		}
		else {
			this.setState({
				startDateErrorMsg: ""
			});
		}
	}

	handleChangeend = (date) => {
		const { fromdate } = this.state;
		if (fromdate > date) {
			this.setState({
				endDateErrorMsg: "KC1120",
				startDateErrorMsg: "",
				todate: ""
			});
		}
		else {
			this.setState({
				todate: date,
				endDateErrorMsg: "",
				startDateErrorMsg: "",
			});
		}
	}

	ResetDatepicker = (e) => {
		e.preventDefault();
		return false;
	}

	NameChange = (e) => {
		if (e.target.value.match(/^[a-zA-Z0-9]{0,100}$/)) {
			if (e.target.value.length >= 3 || e.target.value.length === 0) {
				this.setState({
					reportName: e.target.value.trim(),
					reportNameErrorMsg: ""
				});
			} else {
				this.setState({
					reportName: e.target.value.trim(),
					reportNameErrorMsg: "KC2362"
				});
			}
		}
        else {
            this.setState({
                reportNameErrorMsg: "KC2197"
            });
        }
	}

	LastDaysChange = (lastDaysValue, selectedLastPeriodValue) => {
		const lastDays = parseInt(lastDaysValue.trim());
		if (lastDaysValue.trim() === "" || !lastDays) {
            this.setState({
                lastDaysPeriod: "",
				lastDaysErrorMsg: ""
            });
        }
		else {
			let { selectedLastPeriod, brandId } = this.state;
			if (selectedLastPeriodValue) {
				selectedLastPeriod = selectedLastPeriodValue;
			}
			localStorage.setItem("lastDaysPeriod", lastDays);
			this.setState({
				lastDaysPeriod: lastDays
			});
			const maxLimit = {
				'daily' : Number(brandId) === 4 ? 366 : 90,
				'weekly' : Number(brandId) === 4 ? 52 : 14,
				'monthly' : Number(brandId) === 4 ? 12 : 3,
				'quarterly' : Number(brandId) === 4 ? 4 : 1,
				'yearly' : Number(brandId) === 4 ? 1 : 1
			}
			if (selectedLastPeriod === "daily" && (!lastDays || lastDays > maxLimit['daily'])) {
				this.setState({
					lastDaysErrorMsg: Number(brandId) === 4 ? "KC2294" :  "KC2188"
				});
			}
			else if (selectedLastPeriod === "weekly" && (!lastDays || lastDays > maxLimit['weekly'])) {
				this.setState({
					lastDaysErrorMsg: Number(brandId) === 4 ? "KC2295" :  "KC2189"
				});
			}
			else if (selectedLastPeriod === "monthly" && (!lastDays || lastDays > maxLimit['monthly'])) {
				this.setState({
					lastDaysErrorMsg: Number(brandId) === 4 ? "KC2296" :  "KC2190"
				});
			}
			else if (selectedLastPeriod === "quarterly" && (!lastDays || lastDays > maxLimit['quarterly'])) {
				this.setState({
					lastDaysErrorMsg: Number(brandId) === 4 ? "KC2297" :  "KC2191"
				});
			}
			else if (selectedLastPeriod === "yearly" && (!lastDays || lastDays > maxLimit['yearly'])) {
				this.setState({
					lastDaysErrorMsg: Number(brandId) === 4 ? "KC2298" :  "KC2191"
				});
			}
			else {
				this.setState({
					lastDaysErrorMsg: ""
				});
			}
		}
	}

	RecipientsEmailBlur = (e) => {
		var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
		let recipients = e.target.value.split(';');
		recipients.map(item => item.trim());
		recipients = recipients.filter(item => item !== "");
		let invalid = recipients.some((val) => { 
			return !pattern.test(val)
		})
		let findDuplicates = recipients.filter((item, index) => recipients.indexOf(item) != index);
		if (invalid) {
			this.setState({
				isRecipientsValid: false,
				emailErrorMsg: "KC2192"
			});
		}
		else if (findDuplicates.length > 0) {
			this.setState({
				isRecipientsValid: false,
				emailErrorMsg: "KC2193"
			});
		}
		else {
			if (recipients.length > 10) {
				this.setState({
					isRecipientsValid: false,
					emailErrorMsg: "KC2194"
				});
			}
			else {
				this.setState({
					isRecipientsValid: true,
					emailErrorMsg: ""
				});
			}
		}
	}

	RecipientsEmailChange = (e) => { 
		this.setState({
			recipientsEmail: e.target.value
		});
	}

	CommentChange = (e) => {
		if (e.target.value.length > 4000) {
			this.setState({
				commentErrorMsg: "KC2195"
			});
		}
		else {
			this.setState({
				comment: e.target.value,
				commentErrorMsg: ""
			});
		}
	}

	setGenerateType = (event) => {
		if (event.target.value === "ScheduleReport") {
			this.setState({
				generateNow: false,
				scheduleReport: true,
				fromdate: new Date(),
				todate: '',
				maxdate: scheduledMaxDate,
				minDate: new Date(),
				recipientsEmail: localStorage.getItem("user"),
				comment: '',
				noEndDate: true, 
				endDate: false,
				lastDays: true,
				currentDays: false,
				lastDaysPeriod: 0,
				selectedRepetitionPattern: "", 
				selectedLastPeriod: "daily",
				selectedCurrentPeriod: "weekly",
				startDateErrorMsg: '',
				endDateErrorMsg: '',
				lastDaysErrorMsg: '',
				emailErrorMsg: '',
				commentErrorMsg: ''
			});
			$("#ScheduleReportView").css("display","block"); 
	   		$("#GenerateNowView").css("display","none");
			$("#endDateDiv").css("display","none"); 
			$("#scheduledRecipientsEmail").css("display","block"); 
			$("#scheduledComment").css("display","block"); 
		}
		else if (event.target.value === "GenerateNow") {
			this.setState({
				scheduleReport: false,
				generateNow: true,
				fromdate: '',
				todate: '',
				maxdate: new Date(),
				minDate: threeMonthsFromNow,
				recipientsEmail: '',
				comment: '',
				noEndDate: true, 
				endDate: false,
				lastDays: true,
				currentDays: false,
				lastDaysPeriod: 0,
				selectedRepetitionPattern: "", 
				selectedLastPeriod: "daily",
				selectedCurrentPeriod: "weekly",
				startDateErrorMsg: '',
				endDateErrorMsg: '',
				lastDaysErrorMsg: '',
				emailErrorMsg: '',
				commentErrorMsg: ''
			});
			$("#GenerateNowView").css("display","block"); 
			$("#ScheduleReportView").css("display","none");
			$("#scheduledRecipientsEmail").css("display","none"); 
			$("#scheduledComment").css("display","none"); 
		}
	}

	setEndDatetype = (event) => {
		if (event.target.value === "NoEndDate") {
			this.setState({
				noEndDate: true,
				endDate: false,
				todate: '',
				endDateErrorMsg: ''
			});
			$("#endDateDiv").css("display","none"); 
		}
		else if (event.target.value === "EndDate") {
			this.setState({
				noEndDate: false,
				endDate: true
			});
			$("#endDateDiv").css("display","block");
		}
	}

	setDataPeriodtype = (event) => {
		if (event.target.value === "LastDays") {
			this.setState({
				lastDays: true,
				currentDays: false,
				selectedCurrentPeriod: "weekly"
			});
		}
		else if (event.target.value === "CurrentDays") {
			this.setState({
				lastDays: false,
				currentDays: true,
				lastDaysErrorMsg: '',
				lastDaysPeriod: '',
				selectedLastPeriod: 'daily'
			});
		}
	}

	/**
   * Select or Un-Select Options
   */
	 isOptionSelectationChange = (e, item) => {
		const {optionsDropDownSource} = this.state;
		let optionList = [...optionsDropDownSource];
		let index = optionList.indexOf(item);
		optionList[index].checked = e.target.checked;
		
		this.setState({ 
			optionsDropDownSource: optionList, 
		});
	}

	scheduledTimeChange = (value) => {
        let startsWith = value[0];
        const {hourFormat} = this.state;

        this.setState({
            scheduledTime_mask: [
                hourFormat === "false" ? /[0-2]/ : /[0-1]/,
                hourFormat === "true" && startsWith === "1" ? /[0-2]/ : startsWith === "0" ? /[0-9]/ : hourFormat === "false" && startsWith === "2" ? /[0-3]/ : /[0-9]/,
                ':',
                /[0-5]/,
                /[0-9]/
            ],
            scheduledTimeValue: value
        });
	}
	
	selectedRepetitionPattern (e) {		
		this.setState({ selectedRepetitionPattern: e.target.value });
	}

	selectedLastPeriod (e) {		
		const { lastDaysPeriod } = this.state;
		this.setState({ selectedLastPeriod: e.target.value, lastDaysErrorMsg: '' });
		if (lastDaysPeriod) {
			this.LastDaysChange(lastDaysPeriod ? lastDaysPeriod.toString() : '', e.target.value);
		}
	}

	selectedCurrentPeriod (e) {		
		this.setState({ selectedCurrentPeriod: e.target.value });
	}

	AMPMformatChange (e) {		
		this.setState({ AMPMformat: e.target.value });
	}

	scheduledHourChange (e) {
		this.setState({ scheduledHourValue: e.target.value });
	}

	scheduledMinsChange (e) {
		this.setState({ scheduledMinsValue: e.target.value });
	}

	showMessage = (option) => {
		const filterValue = option.filter((i)=>i.checked === true);
		if(filterValue.length && filterValue.length > 1){
			return true;
		}else{
			return false;
		}
	}

    setBrandList = async () => {
        try {
            let cgids = localStorage.getItem("custGroupID");
			// let brandIds = localStorage.getItem("brandId")
            let url = `${UnitInfo.uniqueBrands}`;
            const response = await http.get(url,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'cgid': cgids ? cgids : "NA",
                        'cid': CUSTOMER_ID,
						'brandid': BRAND_ID
                    },
                    data: {}
                });
            let brandIDNameArr = response.data.brands.map(function (item) {
                return {
                    BRAND_NAME: item.BRAND_NAME,
                    BRAND_ID: item.BRAND_ID
                }
            });
            let uniqueObjArray = [...new Map(brandIDNameArr.map((item) => [item["BRAND_ID"], item])).values()];
            let brandIDName = uniqueObjArray.sort((a, b) => (a.BRAND_NAME > b.BRAND_NAME) ? 1 : ((b.BRAND_NAME > a.BRAND_NAME) ? -1 : 0));
            localStorage.setItem("availableUnitsBrand", JSON.stringify(brandIDName));
        } catch (error) {
            return error;
        }
    }

	componentWillUnmount() {
		localStorage.setItem("availableBrands", []);
	}

	render() {
		let { generateNow, scheduleReport, loaderIconVisible, reportName, fromdate, todate, scheduledHourValue, scheduledMinsValue, noEndDate, endDate, lastDays, currentDays, hourFormat, hourArray } = this.state;
		const { brandDropDownSource, optionsDropDownSource, isOptionsListLoaded, brandId, isBrandListLoaded, reportType, isRecipientsValid, lastDaysPeriod, selectedRepetitionPattern, selectedLastPeriod, selectedCurrentPeriod, AMPMformat, isEdit, recipientsEmail, comment, brandListResponseMessage, selectedBrandID  } = this.state;
		const { reportNameErrorMsg, startDateErrorMsg, endDateErrorMsg, lastDaysErrorMsg, emailErrorMsg, commentErrorMsg } = this.state;
		const { formatMessage } = this.props.intl;
		const { locale } = this.props;
		let brandIdValue = localStorage.getItem("selectedbrandID")
		let isNextEnabled = false
		if (generateNow) {
			if (reportName && reportName.length >= 3 && fromdate && todate && brandId && isRecipientsValid && (fromdate <= todate)) {
				isNextEnabled = true
			}
		}
		else {
			if (reportName && reportName.length >= 3 && selectedRepetitionPattern && fromdate && brandId && isRecipientsValid && scheduledHourValue && scheduledMinsValue && lastDaysErrorMsg === "") {
				if ((lastDays && lastDaysPeriod) || currentDays) {
					isNextEnabled = true;
				}
				if (endDate && (fromdate > todate || !todate)) {
					isNextEnabled = false;
				}
				if (scheduleReport && !recipientsEmail) {
					isNextEnabled = false;
				}
			}
		}
		let showLeftSideDetails;
		showLeftSideDetails = (
			<div class="newReportWrapperL">
				<div class="newReportNS forMObileR" onChange={this.setGenerateType.bind(this)}> 
					<ul>
						<li>
							<div class={isEdit ? "customRadioBtn disabled" : "customRadioBtn"}>
								<input id="GenerateNowMobile" type="radio" checked={generateNow} name="ReportNSMobile" value="GenerateNow" />
								<label for="GenerateNowMobile"><FormattedMessage id="KC2128"/></label>
							</div>
						</li>
						<li>
							<div class="customRadioBtn">
								<input id="ScheduleReportMobile" type="radio" checked={scheduleReport} name="ReportNSMobile" value="ScheduleReport" />
								<label for="ScheduleReportMobile"><FormattedMessage id="KC2129"/></label>
							</div>
						</li> 
					</ul>
				</div> 
				<div class="reportTypeList">
					<ul>
						<li><label><FormattedMessage id="KC2125"/> *</label></li>  
						<li>
							<input type="text" value={reportName} onChange={(e) => this.NameChange(e)} maxLength="50" class={isEdit ? "form-control disabled" : reportNameErrorMsg ? "form-control is-invalid" : "form-control"} placeholder={formatMessage({id:'KC2887'})}  />
							{reportNameErrorMsg && <div class="invalid-feedback">
								<FormattedMessage id={reportNameErrorMsg}/>
							</div>}
						</li>
					</ul>
					<ul>
						<li><label><FormattedMessage id="KC2126"/> *</label></li>  
						<li className="minHeight45">
							{isBrandListLoaded && brandDropDownSource && brandDropDownSource.length && brandDropDownSource.length === 1 ? <label>{brandDropDownSource[0].BRAND_NAME}</label> : isBrandListLoaded && <select class={isEdit ? "form-control disabled" : "form-control"} onChange={(e) => this.OnBrandChange(e)}>
								{ brandDropDownSource.length >= 1 && <option value={0}>{formatMessage({ id: 'KC2140' })}</option> }
								{ isBrandListLoaded && brandDropDownSource.length === 0 && <option value={0}>{formatMessage({ id: 'KC2140' })}</option> }
								{ isBrandListLoaded && brandDropDownSource.map((item,i) => 						   
									<option key={i} value={item.ID} selected={brandId === item.ID} data-brand-unit-limit={item.limit}>{item.BRAND_NAME}</option>  
								)}	
							</select>}
						</li> 
					</ul>
				</div>
				<div class="reportOptions"> 
					<h6><FormattedMessage id="KC2127"/> {optionsDropDownSource && optionsDropDownSource.length > 0 ? brandIdValue != '0' && brandIdValue != '' && <button type="button" class="btn reportTypeInfo" data-toggle="modal" data-target="#optionsModal">&nbsp;</button> : ""}</h6> 
						{(!optionsDropDownSource || optionsDropDownSource.length === 0 &&  brandIdValue == '0' && isBrandListLoaded && !brandListResponseMessage || isBrandListLoaded && brandIdValue == '0' && brandId === 0) && <div class="noBrandSelect">
							{<FormattedMessage id="KC2200"/>}
						</div> }
						{(!optionsDropDownSource || optionsDropDownSource.length === 0 &&  brandIdValue == '' && isBrandListLoaded && !brandListResponseMessage || isBrandListLoaded && brandIdValue == '' && brandId === 0) && <div class="noBrandSelect">
							{<FormattedMessage id="KC2200"/>}
						</div> }
						{ isOptionsListLoaded && (!optionsDropDownSource || optionsDropDownSource.length === 0 && isBrandListLoaded && brandIdValue > '0' && (!brandListResponseMessage || brandListResponseMessage === 'Brand option list generated successfully.') && brandId !== 0) && <div class="noBrandSelect">
							{<FormattedMessage id="KC2202"/>}
						</div> }
						
						{/* {this.showMessage(optionsDropDownSource) ? <div className='reportLimitMessage'>*<FormattedMessage id="KC2303" values={{ count: this.state.brandUnitLimit }}/></div> : null} */}
						<div class="reportOptionsList"> 
							<ul> 
							{ brandIdValue != '' && brandIdValue != '0' && brandDropDownSource.length > 0 && optionsDropDownSource && optionsDropDownSource.length > 1 && ('' + optionsDropDownSource[0].brand_id + '' === brandIdValue)  && optionsDropDownSource.map((item,i) => 
								<li> 
									<div class={item.disabled ? "customCheckbox customCheckboxWOlabel disabled" : "customCheckbox customCheckboxWOlabel"}>
										<input id={"FleetData"+i} name={"reportTypeList"+i} checked={item.checked} type="checkbox" value={item.checked} onChange={(e) => this.isOptionSelectationChange(e, item)} />
										<label for={"FleetData"+i}>{item.text_translations[locale].option}</label>
									</div> 
								</li> 
							)}	
						</ul>  
					</div>
					<div className='clearBoth'></div>
					{brandDropDownSource.length > 0 && optionsDropDownSource && optionsDropDownSource.length > 1 && ('' + optionsDropDownSource[0].brand_id + '' === brandIdValue) && this.showMessage(optionsDropDownSource) ? <div className='reportLimitMessage'>*<FormattedMessage id="KC2303" values={{ count: this.state.brandUnitLimit }}/></div> : <div className='reportLimitMessage'>&nbsp;</div>}
				</div>  
			 </div>
		);

		return (

			<div id="adminWrapper">
				<div id="fleetExportReport" className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
					<div className="loadingProgressIn"></div>
				</div>
				<div id="page-content-wrapper">
					<div class="reportWrapper">
						<div class="adminBgHeader">
							<ul>
								<li>
									<Link to={reportType === 1 ? "/scheduledReportList" : "/reportList"} title={formatMessage({ id: 'KC0989' })} class="backIcon">&nbsp;</Link>
								</li>
								<li className="txtboldmsg">
									{reportType === 1 ? <FormattedMessage id="KC0793"/> : <FormattedMessage id="KC0793"/>}
				             	</li>
								<li>
									<Link className={isNextEnabled ? "activeState" : "disabled"} id="AssignUpdate" to={{
										pathname: "/assignReportUnitToTopology",
										state: {
											reportType: this.state.scheduleReport ? 1 : 0,
											nameFromReport: this.state.reportName,
											startDateFromReport: this.state.fromdate,
											endDateFromReport: this.state.todate,
											selectedoptionsFromReport: optionsDropDownSource,
											generateNow: this.state.generateNow,
											scheduleReport: this.state.scheduleReport,
											recipientsEmail : this.state.recipientsEmail,
											comment : this.state.comment,
											selectedRepetitionPattern: this.state.selectedRepetitionPattern,
											selectedLastPeriod: this.state.selectedLastPeriod,
											selectedCurrentPeriod: this.state.selectedCurrentPeriod,
											lastDays: this.state.lastDays,
											currentDays: this.state.currentDays,
											lastDaysPeriod: this.state.lastDaysPeriod,
											hourFormat: this.state.hourFormat,
											scheduledHourValue: this.state.scheduledHourValue,
											scheduledMinsValue: this.state.scheduledMinsValue,
											noEndDate: this.state.noEndDate,
											endDate: this.state.endDate,
											AMPMformat: this.state.AMPMformat,
											isEdit: this.state.isEdit,
											id: this.state.id,
											schedule_id: this.state.schedule_id,
											checkedUnits: this.state.checkedUnits,
											selectedGroupName: this.state.selectedGroupName,
											selectedDynasty: this.state.selectedDynasty,
											brandListResponseMessage: this.state.brandListResponseMessage
										}
									}} title={formatMessage({ id: 'KC0543' })}><FormattedMessage id="KC0543"/></Link>
								</li>
							</ul>
						</div>
						<div class="newReportWrapper">
							{showLeftSideDetails}
							<div class="newReportWrapperR">
								<div class="newReportNS forDesktopR" onChange={this.setGenerateType.bind(this)}> 
									<ul>
										<li>
											<div class={isEdit ? "customRadioBtn disabled" : "customRadioBtn"}>
												<input id="GenerateNow" type="radio" checked={generateNow} name="ReportNS" value="GenerateNow" />
												<label for="GenerateNow"><FormattedMessage id="KC2128"/></label>
											</div>
										</li>
										<li>
											<div class="customRadioBtn">
												<input id="ScheduleReport" type="radio" checked={scheduleReport} name="ReportNS" value="ScheduleReport" />
												<label for="ScheduleReport"><FormattedMessage id="KC2129"/></label>
											</div>
										</li> 
									</ul>
								</div>  
								<div class="newReportForm" id="GenerateNowView">
									<h6><FormattedMessage id="KC2184"/> *</h6>
									<ul>
										<li>
											<label><FormattedMessage id="KC2179"/></label>
											<div class="input-group">
												<DatePicker locale={datePickerLocale} autoComplete="off" className={startDateErrorMsg ? "form-control dayDatepicker is-invalid" : "form-control dayDatepicker"} id="fromdate" showMonthDropdown showYearDropdown dropdownMode="select" onKeyDown={(e) => this.ResetDatepicker(e)} selected={this.state.fromdate} onChange={this.handleChangestart} minDate={this.state.minDate} maxDate={this.state.maxdate} placeholderText={formatMessage({id:'KC0835'})} />
											</div>

											<label><FormattedMessage id="KC2180"/></label>
											<div class="input-group">
												<DatePicker locale={datePickerLocale} autoComplete="off" className={endDateErrorMsg ? "form-control dayDatepicker is-invalid" : "form-control dayDatepicker"} id="todate" showMonthDropdown showYearDropdown dropdownMode="select" onKeyDown={(e) => this.ResetDatepicker(e)} selected={this.state.todate} onChange={this.handleChangeend} minDate={this.state.minDate} maxDate={this.state.maxdate} placeholderText={formatMessage({id:'KC0835'})} />
											</div>
										</li>
										<li>
											
										</li>
									</ul>
									{startDateErrorMsg && <div class="invalid-feedback">
										<FormattedMessage id={startDateErrorMsg}/>
									</div>}
									{endDateErrorMsg && <div class="invalid-feedback">
										<FormattedMessage id={endDateErrorMsg}/>
									</div>}
									<div class="newReportForm GenerateNowEmail">
										<h6><FormattedMessage id="KC2136"/> {scheduleReport ? "*" : ""}</h6>
										<textarea class={emailErrorMsg ? "form-control is-invalid" : "form-control"} rows="2" cols="50" value={recipientsEmail} onChange={(e) => this.RecipientsEmailChange(e)} onBlur={(e) => this.RecipientsEmailBlur(e)} placeholder={formatMessage({ id: "KC2138"})}></textarea>
										{emailErrorMsg && <div class="invalid-feedback">
											<FormattedMessage id={emailErrorMsg}/>
										</div>}
									</div>  
									<div class="newReportForm">
										<h6><FormattedMessage id="KC2137"/></h6>
										<textarea class={commentErrorMsg ? "form-control is-invalid" : "form-control"} rows="2" cols="50" value={comment} onChange={(e) => this.CommentChange(e)} placeholder={formatMessage({ id: "KC2139"})}></textarea>
										{commentErrorMsg && <div class="invalid-feedback">
											<FormattedMessage id={commentErrorMsg}/>
										</div>}
									</div> 
								</div>
								<div id="ScheduleReportView">  
									<div class="newReportForm">
										<div class="newReportFormT">
										<ul>
											<li>
												<h6><FormattedMessage id="KC2130"/> *</h6>
												<div class="input-group selectRepetitionPattern">
													<select onChange={(e) => this.selectedRepetitionPattern(e)} value={selectedRepetitionPattern} class="form-control"> 
														<option value="" selected>{formatMessage({ id: 'KC2131' })}</option>
														<option value="daily">{formatMessage({ id: 'KC0789' })}</option>
														<option value="weekly">{formatMessage({ id: 'KC0788' })}</option>
														<option value="monthly">{formatMessage({ id: 'KC0787' })}</option>
														<option value="quarterly">{formatMessage({ id: 'KC2167' })}</option>
													</select>
												</div>
											</li> 
											<li> 
												<h6><FormattedMessage id="KC2132"/> *</h6>
												<div class="reportScheduleForm">
													<ul>
														<li>
															<label><FormattedMessage id="KC0695"/></label>
															<div class="input-group startDateSelection">
																<DatePicker locale={datePickerLocale} autoComplete="off" className="form-control dayDatepicker" id="fromdate" showMonthDropdown showYearDropdown dropdownMode="select" onKeyDown={(e) => this.ResetDatepicker(e)} selected={this.state.fromdate} onChange={this.handleChangestart} minDate={this.state.minDate} maxDate={this.state.maxdate} placeholderText={formatMessage({id:'KC0835'})} />
															</div>
														</li>
														<li>
															<label><FormattedMessage id="KC0144"/></label>
															<div class="input-group timeSelection">
																<select onChange={(e) => this.scheduledHourChange(e)} value={scheduledHourValue} class="form-control timeHH"> 
																	<option value="">hh</option> 
																	{hourArray.map((item,j) => 						   
																		<option key={j} value={item}>{item}</option>  
																	)}	
																</select>
																<select onChange={(e) => this.scheduledMinsChange(e)} value={scheduledMinsValue} class="form-control timeMM">  
																	<option value="">mm</option>
																	<option value="00">00</option>
																	<option value="15">15</option> 
																	<option value="30">30</option>  
																	<option value="45">45</option> 
																</select>  
																{hourFormat === "true" ? <select onChange={(e) => this.AMPMformatChange(e)} value={AMPMformat} class="form-control timeAmPm">  
																	<option value="AM" selected>{formatMessage({ id: 'KC1406' })}</option>
																	<option value="PM">{formatMessage({ id: 'KC1407' })}</option>
																</select> : ""}
															</div> 
														</li> 
													</ul>
												</div>
												{startDateErrorMsg && <div class="invalid-feedback">
													<FormattedMessage id={startDateErrorMsg}/>
												</div>}
											</li> 
										</ul>
										</div>
									</div>  
									<div class="newReportForm" onChange={this.setEndDatetype.bind(this)}> 
										<div class="newReportFormT noEndDate">
											<ul>
												<li>
													<div class="customRadioBtn">
														<input id="NoEndDate" type="radio" checked={noEndDate} name="NoEndDate" value="NoEndDate" />
														<label for="NoEndDate"><FormattedMessage id="KC2133"/></label>
													</div>
												</li>
												<li>
													<div class="customRadioBtn">
														<input id="EndDate" type="radio" checked={endDate} name="EndDate" value="EndDate" />
														<label for="EndDate"><FormattedMessage id="KC0696"/></label>
													</div>
													<div class="endDatePicker">
														<DatePicker locale={datePickerLocale} autoComplete="off" className={endDate ? "form-control dayDatepicker" : "form-control dayDatepicker disabled"} id="todate" showMonthDropdown showYearDropdown dropdownMode="select" onKeyDown={(e) => this.ResetDatepicker(e)} selected={this.state.todate} onChange={this.handleChangeend} minDate={this.state.minDate} maxDate={this.state.maxdate} placeholderText={formatMessage({id:'KC0835'})} />
													</div>
													{endDateErrorMsg && <div class="invalid-feedback">
														<FormattedMessage id={endDateErrorMsg}/>
													</div>}
												</li> 
											</ul>
										</div>
									</div> 
									<div class="datePeriodDiv">
										<ul>
											<li><h6><FormattedMessage id="KC2184"/> *</h6></li>
											<li>
												<div class="newReportForm"> 	
													<ul>
														<li>
															<div class="customRadioBtn" onChange={this.setDataPeriodtype.bind(this)}>
																<input id="LastDays" type="radio" checked={lastDays} name="DatePeriod" value="LastDays" />
																<label for="LastDays"><FormattedMessage id="KC2134"/></label>
															</div>
															<div class="input-group daysNumber">
																<input type="text" maxLength={3} value={lastDaysPeriod} onChange={(e) => this.LastDaysChange(e.target.value)} class={lastDaysErrorMsg ? "form-control is-invalid" : lastDays ? "form-control" : "form-control disabled"} placeholder="0" />
															</div> 
															<div class="input-group currentDaysDiv">
																<select onChange={(e) => this.selectedLastPeriod(e)} value={selectedLastPeriod} class={lastDays ? "form-control" : "form-control disabled"}>  
																	<option value="daily" selected>{formatMessage({ id: 'KC1894' })}</option>
																	<option value="weekly">{formatMessage({ id: 'KC2168' })}</option>
																	<option value="monthly">{formatMessage({ id: 'KC2169' })}</option> 
																	<option value="quarterly">{formatMessage({ id: 'KC0088' })}</option> 
																	{Number(brandId) === 4 && <option value="yearly">{formatMessage({ id: 'KC0089' })}</option> }
																</select>  
															</div> 
															{lastDaysErrorMsg && <div class="invalid-feedback">
																<FormattedMessage id={lastDaysErrorMsg}/>
															</div>}
														</li>
														<li>
															<div class="customRadioBtn" onChange={this.setDataPeriodtype.bind(this)}>
																<input id="CurrentDays" type="radio" checked={currentDays} name="DatePeriod" value="CurrentDays" />
																<label for="CurrentDays"><FormattedMessage id="KC2135"/></label>
															</div>
															<div class="input-group currentDaysDiv">
																<select onChange={(e) => this.selectedCurrentPeriod(e)} value={selectedCurrentPeriod} class={currentDays ? "form-control" : "form-control disabled"}> 
																	<option value="weekly" selected>{formatMessage({ id: 'KC0086' })}</option>
																	<option value="monthly">{formatMessage({ id: 'KC0087' })}</option> 
																	<option value="quarterly">{formatMessage({ id: 'KC0088' })}</option> 
																	{Number(brandId) === 4 && <option value="yearly">{formatMessage({ id: 'KC0089' })}</option> }
																</select>   
															</div>
														</li> 
													</ul>
												</div>
											</li>
										</ul>
									</div>
								</div> 
							</div>
							<div class="clear"></div>
								<div class="newReportWrapperL">
									<div class="newReportForm" id="scheduledRecipientsEmail">
										<h6><FormattedMessage id="KC2136"/> {scheduleReport ? "*" : ""}</h6>
										<textarea class={emailErrorMsg ? "form-control is-invalid" : "form-control"} rows="2" cols="50" value={recipientsEmail} onChange={(e) => this.RecipientsEmailChange(e)} onBlur={(e) => this.RecipientsEmailBlur(e)} placeholder={formatMessage({ id: "KC2138"})}></textarea>
										{emailErrorMsg && <div class="invalid-feedback">
											<FormattedMessage id={emailErrorMsg}/>
										</div>}
									</div> 
								</div>
								<div class="newReportWrapperR" id="scheduledComment">
									<div class="newReportForm">
										<h6><FormattedMessage id="KC2137"/></h6>
										<textarea class={commentErrorMsg ? "form-control is-invalid" : "form-control"} rows="2" cols="50" value={comment} onChange={(e) => this.CommentChange(e)} placeholder={formatMessage({ id: "KC2139"})}></textarea>
										{commentErrorMsg && <div class="invalid-feedback">
											<FormattedMessage id={commentErrorMsg}/>
										</div>}
									</div>
								</div>
						</div>
					</div>
				</div>
				<UserManagementHeader headerNameStringID='KC1000' headerName="Report Management" activeClass="reportNav" />
				{/* Options Details Modal */}
				<div class="modal fade reportDetailsModal" data-keyboard="true" data-backdrop="static" id="optionsModal" tabindex="-1" role="dialog" aria-labelledby="optionsModalLabel">
					<div class="modal-dialog modal-md">
						<div class="modal-content">
							<div class="modal-header modal-header-danger">
							<FormattedMessage id="KC2127"/> <button type="button" class="close" data-dismiss="modal" aria-label="Close">&nbsp;</button> 
							</div>
							<div class="modal-body"> 
								<div class="reportDetailsD" style={{height: "515px"}}> 
									{ optionsDropDownSource.map((item) => 
										<div class="reportDetailsD"> 
											<h6>{item.text_translations[locale].option}</h6>
											<p>{item.text_translations[locale].description}</p>
										</div> 
									)}
								</div> 
							</div> 
						</div>
					</div>
				</div>
				{/* //Options Modal */}
			</div>
		)
	}
};

function mapStateToProps(state) {
	return {
		locale: state.localeInfo.locale,
	}
}

export default connect(mapStateToProps)(injectIntl(newReportWithOptions));
